.card .card-body mat-form-field {
    width: 100%;
}
.card {
    margin-bottom: 0;
}
.card .card-body {
    padding: 15px;
}
.card .card-header {
    padding: 0;
}
.card .card-footer {
    padding: 10px 0;
}
.mr-10 {
    margin-right: 10px;
}
.ml-10 {
    margin-left: 10px;
}