/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* Material Components */
.mat-card{
    overflow: hidden;
    &.custom-card{
        .mat-card-content {
            padding: 10px;
            height: 65px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .mat-card-header{
            margin: -16px -16px 0;
            .mat-card-title{
                font-size: 14px;
            }
        }
        .mat-card-actions{
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
.search-tab.mat-tab-group  {
    .mat-tab-body-content {
        overflow: initial !important;
    }
}